.experience-container {
	margin-bottom: 1rem;
}

.experience-title {
	font-size: 1.5rem;
}

.experience-technology-container {
	margin: 0;
	overflow: hidden;
	display: flex;
	gap: 3rem;
}

@media only screen and (max-width: 760px) {
	/* Display technologies hotizontally on mobile */
	.experience-technology-container {
		flex-direction: column;
		gap: 5px;
	}

	.experience-technology-container > li {
		margin-bottom: 0;
	}
}

.experience-tenure {
	font-weight: 300;
}

.experience-technology-container > li {
	display: list-item;
	list-style-type: "⛏";
	padding-inline-start: 1ch;
	line-height: 1.5rem;
}
