.projects {
	min-height: 100vh;
}
h1#project-title {
	padding-top: 100px;
	text-align: center;
	color: whitesmoke;
	font-weight: 500;
	font-size: 3rem;
}

h2#project-filter-title {
	color: white;
	margin-top: 1rem;
	margin-left: 1.5rem;
	font-size: revert;
	text-decoration: underline;
	text-underline-position: under;
}

@keyframes comeIn {
	0% {
		transform: scale(0);
	}
	75% {
		transform: scale(1.03);
	}
	100% {
		transform: scale(1);
	}
}

.xmasonry .xblock {
	animation: comeIn ease 0.5s;
	animation-iteration-count: 1;
	transition: left 0.3s ease, top 0.3s ease;
}

div.project-component {
	background-color: #3b3b3b;
	margin: 1rem;
	border-radius: 10px;
	padding: 1rem;
	height: fit-content;
	color: white;
}
div.project-component:hover {
	background-color: #3e4c59;
}

.project-description {
	font-size: 16px;
	line-height: 1.5rem;
}

ul.project-tags {
	list-style: none;
	width: fit-content;
	margin: 5px 0;
	padding: unset;
	display: inline-block;
	font-size: 12px;
	font-family: monospace;
}

li.project-tags-item {
	margin: 0 5px;
	padding: 0.5rem 1rem;
	border-radius: 10px;
	width: fit-content;
	border: 2px solid transparent;
}

a,
a:visited {
	text-decoration: none;
	decoration: none;
	color: inherit;
}

.welp-2-14 {
	color: whitesmoke;
	margin: 2rem;
	font-weight: 300;
	line-height: 1.25;
	font-size: 1.2rem;
}
