#AboutMe {
	color: #e4e6eb;
	margin: 1rem;
	font-size: 3rem;
    border: 5px solid #c48bc3;
	border-radius: 10px;
    max-width:85%;
    height: fit-content;
}

#AboutMeContainer {
	display: grid;
	grid-template-areas: "main sidebar";
	grid-column-gap: 3rem;
}

@media only screen and (max-width: 770px) {
	/* For mobile phones and smaller-screen windows: */
	#AboutMeContainer {
		display:block;
	}

	#AboutMeLegend {
		padding: 0.5rem;
		font-weight: 500;
	}

	/*No need to show avatar on mobile*/
	#AboutMeAvatarCanvas {
		display: none;
	}
}

@media only screen and (min-width: 760px) {
	/* For desktop: */

	#AboutMeLegend {
		padding: 0.5rem;
		font-weight: 500;
	}

	#AboutMeContainer {
		padding: 1rem;
		grid-template-columns: 3fr 1fr;
	}

	#AboutMeAvatar {
		grid-area: sidebar;
		display: flex;
	}
}

@media only screen and (min-width: 760px) 
and (max-width: 1200px) {
	/* For desktop: */

	#AboutMeLegend {
		padding: 0.5rem;
		font-weight: 500;
	}

	#AboutMeContainer {
		padding: 1rem;
		grid-template-columns: 3fr 1fr;
	}

	#AboutMeAvatar {
		grid-area: sidebar;
		display: flex;
	}

	#AboutMeAvatarCanvas {
		overflow:visible;
        width:20vw !important;;
	}
}


#AboutMeText {
	grid-area: main;
	display: flex;
	font-size: 1rem;
	font-weight: 250;
	gap: 1rem;
	display: flex;
	flex-direction: column;
}
