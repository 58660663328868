:host {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  color: #333;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 2.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

/* Remove onclick blue border */
*:focus {
  outline: none !important;
  outline-style: none !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

html {
  background-color: #212529;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 1.5rem;
  height: 1.5rem;
  right: 1.5rem;
  top: 1.5rem;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #323649;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Wrapper for item list */
.bm-item-list {
  padding: 0.8em;
  color: white;
  font-weight: 500;
  font-size: 20px;
}

/* Individual item */
.bm-item {
  display: inline-block;
  line-height: 2rem;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

#IntroPage {
  height: 100vh;
}

#AboutMePage {
  display: flex;
  place-content: center;
  padding: 2rem 0;
  overflow-wrap: anywhere;
}