div#filter-container {
    display: flex;
    flex-wrap: wrap;
    color: black;
    margin: 1rem 2rem;
  }
  
  div#all {
    font-weight: 500;
  }
  
  .MuiToggleButton-root {
    padding: 0 10px !important; 
    border-radius: 10px !important;
    margin: 0.5rem 6px!important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: monospace !important;
    border: 2px solid transparent !important;
    line-height: 2rem !important;
  }

  .Mui-selected {
    opacity: 0.5;
  }